<template>
  <div>
    <header class="bg-primary-400 px-2">
      <div class="mx-4 py-5">
        <div class="sm:flex sm:items-center sm:justify-between">
          <div>
            <h3 class="text-3xl leading-tight tracking-tight text-white">
              {{ title }}
            </h3>
            <!-- <p class="text-gray-200" v-if="description">
            {{ description }}
          </p> -->
          </div>
          <div
            class="sm:ml-4"
            v-if="button"
          >
            <!--
              Conditional role logic could be moved to script if needed later but as it is now
              it's easier like this (no need to modify every files to make the same thing everywhere)
            -->
            <button
              v-if="userRoles.includes('admin') || userRoles.includes('director') || userRoles.includes('community-manager')"
              type="button"
              class="inline-flex items-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white ring-1 ring-green-200 shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              @click="button.action"
            >
              <i
                :class="button.icon"
                v-if="button.icon"
                class="-ml-1 mr-2 h-5 w-5"
              />
              {{ button.text }}
            </button>
          </div>
          <div v-else>
            <slot />
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    button: {
      type: Object
    }
  },
  computed: {
    userRoles () {
      return this.$store.getters.roles
    }
  }
}
</script>
