<script>
import PageHeader from '@/components/PageHeader'
import PageContent from '../components/PageContent.vue'
import { mapGetters } from 'vuex'
import { http } from '../services/api.service'

export default {
  components: { PageHeader, PageContent },
  computed: {
    ...mapGetters(['user'])
  },
  data () {
    return {
      lastname: null,
      firstname: null,
      email: null,
      isValidEmail: true,
      preview: null,
      storageUrl: process.env.VUE_APP_STORAGE_URL
    }
  },

  mounted () {
    this.lastname = this.user.lastname
    this.firstname = this.user.firstname
    this.email = this.user.email
  },

  methods: {
    checkValidEmails () {
      this.isValidEmail = false
      const regex = /^[\w-.]+@([\w-]+\.)+[\wa-]{2,4}(;[\w-.]+@([\w-]+\.)+[\w-]{2,4})*$/
      this.isValidEmail = regex.test(this.email)
      if (!this.isValidEmail) {
        this.$emit('error', true)
      }
    },
    uploadAvatar (event) {
      const file = event.target.files[0]
      const formData = new FormData()
      if (file) {
        formData.append('avatar', file)
      }
      http.post(`/me/upload`, formData).then(({ data }) => {
        this.$store.commit('SET_USER', data)
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Avatar modifié avec succès',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
      }).catch(err => {
        console.log(err)
      })
    },
    updateProfile () {
      const updates = {
        lastname: this.lastname,
        firstname: this.firstname,
        email: this.email
      }
      http.put('/me', updates).then(({ data }) => {
        this.$store.commit('SET_USER', data)
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Profil mis à jour modifié avec succès',
          position: 'is-bottom-left',
          type: 'is-success',
          hasIcon: true
        })
      }).catch(err => console.log(err))
    }
  }
}
</script>

<template>
  <div>
    <PageHeader title="Mon Profil" />
    <PageContent>
      <div class="shadow sm:overflow-hidden sm:rounded-md">
        <div class="space-y-6 bg-white py-6 px-4 sm:p-6">
          <div v-if="user" class="grid grid-cols-4">
            <div class="col-span-4 lg:col-span-1">
              <label for="avatar" class="block text-center">Avatar</label>
              <div class="flex flex-col justify-around items-center">
                <input
                  ref="preview"
                  type="file"
                  name="preview"
                  id="file"
                  @change="uploadAvatar"
                  hidden
                />
                <img
                  v-if="user.avatar"
                  class="w-60 h-60 rounded-full"
                  :src="`${storageUrl}/avatars/${user.avatar}`"
                  alt="avatar"
                />
                <div
                  v-else
                  class="w-60 h-60 rounded-full bg-gray-100 flex justify-center items-center cursor-pointer"
                  @click="$refs.preview.click()"
                >
                  <span>Pas d'avatar disponible</span>
                </div>
                <t-button @click="$refs.preview.click()"> <i class="fas fa-upload"></i> Ajouter/Modifier un avatar</t-button>
              </div>
            </div>
            <div class="col-span-4 lg:col-span-3 flex flex-col gap-6 justify-center">
              <t-input-group
                label="Email"
                :variant="{ 'danger': !isValidEmail}"
                :feedback="!isValidEmail? 'Veuillez saisir une adresse email valide' : ''"
              >
                <t-input
                  :variant="{ 'danger' : !isValidEmail }"
                  v-model="email"
                  @input="checkValidEmails"
                />
              </t-input-group>
              <t-input-group label="Nom">
                <t-input
                  v-model="lastname"
                />
              </t-input-group>
              <t-input-group
                label="Prénom"
              >
                <t-input
                  v-model="firstname"
                />
              </t-input-group>
            </div>
          </div>

        </div>
        <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
          <button
            type="submit"
            class="inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            :class="{ disabled :isValidEmail }"
            :disabled="!isValidEmail"
            @click="updateProfile"
          >
            Sauvegarder
          </button>
        </div>
      </div>
    </PageContent>
  </div>
</template>
